import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    token: localStorage.getItem("user-token") || null,
    user: localStorage.getItem("user") || null,
  },
  mutations: {
    LOGIN_SUCCESS(state, response) {
      state.token = response.token;
      state.user = response.user;
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + response.token;
      localStorage.setItem("user-token", response.token);
      localStorage.setItem("user", JSON.stringify(response.user));
    },
    DESTROY_TOKEN(state) {
      state.token = null;
      state.user = null;
      axios.defaults.headers.common["Authorization"] = null;
      localStorage.setItem("user-token", "");
      localStorage.setItem("user", "");
    },
  },
  actions: {},
  modules: {},
  getters: {
    user: (state) => state.user?.replace(/"/g, ""),
    isAuthenticated: (state) => !!state.token,
    token: (state) => state.token,
  },
});
