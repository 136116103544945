import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store/index";
import axios from "axios";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {
      title: "Login - Área Distribuidores Feli SA",
    },
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      requiresAuth: true,
      title: "Área Clientes - Área Distribuidores Feli SA",
    },
  },
  {
    path: "/panel",
    component: () => import("../views/Panel.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "avisos-de-pago",
        component: () => import("../views/PanelAvisos.vue"),
        meta: {
          title: "Avisos de Pago - Área Distribuidores Feli SA",
        },
        children: [
          {
            path: "",
            name: "avisos-de-pago",
            components: {
              avisos: () => import("../components/PanelAvisosHome.vue"),
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "nuevo",
            name: "avisos-de-pago-nuevo",
            components: {
              avisos: () => import("../components/PanelAvisosNuevo.vue"),
            },
          },
          {
            path: "detalle/:id",
            name: "avisos-de-pago-detalle",
            components: {
              avisos: () => import("../components/PanelAvisosDetalle.vue"),
            },
          },
          {
            path: "editar/:id",
            name: "avisos-de-pago-editar",
            components: {
              avisos: () => import("../components/PanelAvisosEditar.vue"),
            },
          },
        ],
      },
    ],
  },
  {
    path: "/",
    redirect: "Home",
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title =
    typeof to.meta.title === "string" ? to.meta.title : "Feli SA";

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const token = store.getters.token;
    if (token && token != "undefined") {
      //Actualiza el header de Axios con el Token de Vuex
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + store.getters.token;
      next();
    } else {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next();
  }
});

export default router;
